/* You can add global styles to this file, and also import other style files */


.ngx-otp-input{

    border-style: none !important;
    border-bottom-style: solid !important;
    border-bottom-width:1.5px !important;
    border-bottom-color:#e2e2e2 !important;
    width: 6rem !important;
    border-radius: 0px !important;
    margin-right: 2rem !important;
    outline-style: none !important;
    height: 7rem !important;
    color: #000000 !important;
    text-align: center !important;
    font-size: 2.6rem !important;
    font-weight: 400;
}


.otp-input-box:focus{
    border-bottom-color:#2C66E3;
}

@media only screen and (max-width: 425px){

    .ngx-otp-input{

        border-style: none !important;
        border-bottom-style: solid !important;
        border-bottom-width:1.5px !important;
        border-bottom-color:#e2e2e2 !important;
        width: 4.6rem !important;
        margin-right: 0rem !important;
        border-radius: 0px !important;
        outline-style: none !important;
        height: 7rem !important;
        color: #000000 !important;
        text-align: center !important;
        font-size: 1.6rem !important;
        font-weight: 400;
    }


}